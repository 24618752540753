import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { SEO } from 'components';
import { graphql, Link } from 'gatsby';
import PrevArrow from 'images/shared/PrevArrow';

const OnboardingDevelopers = () => {
  return (
    <>
      <SEO pageSpecificTitle="Onboarding for Developers" />
      <Wrapper>
        <InnerWrapper>
          <BackButton to="/admin/onboarding/">
            <PrevArrow width="1.688rem" />
          </BackButton>
          <h1>
            Onboarding <span>- Developers</span>
          </h1>
          <Container>
            <h2>Welcome to Agency X</h2>
            <p>
              To help you get up to speed with the dev tech and processes we use here at the agency
              we have created the guide below. This guide includes various instructions, helper
              videos, tutorials and useful links that will help you along the way, so make sure to
              add it to your bookmarks before you get started.
            </p>
            <h3>The Basics</h3>
            <h4>Email Setup</h4>
            <p>
              First, we need to setup your email. Once, your email has been created on our
              blacknight servers and you have been provided the password, you can then check out the{' '}
              <Link to="/admin/onboarding/emails">email setup instructions</Link> for setting it up on
              your work PC / Laptop.
            </p>
            <h4>Git / Github</h4>
            <p>You're probably already quite familiar with the Git and Github workflow, but if not or you'd just like to brush up on it a little, here's a little useful course from the EggHeads.<br/><a href="https://egghead.io/courses/practical-git-for-everyday-professional-use" target="blank">EggHead - Practical Git</a></p>
            <h4>GatsbyJs</h4>
            <p>
              We assume you are already familiar with React, however you may or may not be familiar
              with{' '}
              <a href="https://www.gatsbyjs.com/" target="blank">
                GatsbyJs
              </a>
              .
            </p>

            <p>
              This is a react based, graphql powered, static site generator that we use on the
              majority of our websites. It has a rake of handy plugins and tutorials to help build
              performant websites. It has a few quirks and additional setup steps, but once you’ve
              built one or two sites with it you’ll get off the ground.
            </p>

            <p>
              You can use the following tutorial to get started with GatsbyJS:{' '}
              <a href="https://www.gatsbyjs.com/docs/tutorial/" target="blank">
                GatsbyJs Tutorials
              </a>
            </p>
            <h4>Styled-Components</h4>
            <p>
              We use a CSS-in-Js solution to help manage specifity, semantic naming, make theming
              and interactions easier with props and keep CSS scoped to a single component where
              necessary. The solution we opt for is{' '}
              <a href="https://styled-components.com/">styled-components</a>.
            </p>

            <p>
              To use styled-components with Gatsby we simply add the plugin as per the following
              instructions:
              <a
                href="https://www.gatsbyjs.com/plugins/gatsby-plugin-styled-components/"
                target="blank">
                Gatsby Styled-Components Setup
              </a>
            </p>

            <p>
              You can use the following tutorial to get started with styled-components:
              <a href="https://styled-components.com/docs/basics" target="blank">
                Styled Components Basics
              </a>
            </p>
            <h4>Framer Motion</h4>

            <p>
              If you prefer to use CSS based animations that’s ok, but if you want to take your
              animations to the next level and speed up your dev time, you should probably take a
              look at{' '}
              <a href="https://www.framer.com/motion/" target="blank">
                framer-motion
              </a>
              .
            </p>

            <p>
              We use framer on the majority of our projects, so you will want to get at least a
              little bit familiar so you don’t get lost in the wilderness.
            </p>
            <p>
              You can use the following tutorial to get started with framer-motion:{' '}
              <a href="https://www.framer.com/docs/introduction/" target="blank">
                Framer Motion Tutorials
              </a>
            </p>
            <p>
              If you hate docs and prefer learning from video instructions, you can checkout this
              video playlist from the Net Ninja on youtube:
              <a href="https://www.youtube.com/watch?v=2V1WK-3HQNk" target="blank">
                Framer Motion Video Tutorials
              </a>
            </p>
            <h3>A Deeper Dive...</h3>
            <h4>SanityCMS</h4>
            <p>
              Ok, so we don’t like Wordpress... that’s a no-no. Just ew, but we do like a good
              headless CMS. Some clients may require many updates to their site, which can be
              tedious and time-consuming for us. The best option is to offer them a CMS that takes
              the tedious job of uploading new images and articles off our hands.
            </p>

            <p>
              This is where{' '}
              <a href="https://www.sanity.io/" target="blank">
                SanityCMS
              </a>{' '}
              comes in. We originally used NetlifyCMS, but switched to Sanity, because of the
              increased customisability of the data and user interface.
            </p>

            <p>
              Due to the possibilities of a custom user interface, we can build a CMS that is much
              more stylish and user friendly than the ugly, bloated Wordpress admin panel.
            </p>

            <p>
              Sanity’s documentation is fairly decent, however good starting point would be to jump
              in and play around with the the{' '}
              <a
                href="https://www.sanity.io/create?template=sanity-io%2Fsanity-template-gatsby-blog"
                target="blank">
                Gatsby Sanity Starter Template
              </a>
              .
            </p>
            <p>
              Here's a simple starter blog post tutorial:{' '}
              <a
                href="https://www.frontendstumbles.com/gatsby-and-sanity-cms-tutorial/"
                target="blank">
                Gatsby & Sanity - Frontend Stumbles
              </a>{' '}
            </p>
            <p>
              Another good and more recent starter tutorial is availbale here from LogRocket:{' '}
              <a
                href="https://blog.logrocket.com/sanity-cms-for-content-management-in-gatsby/"
                target="blank">
                Gatsby & Sanity - LogRocket
              </a>
            </p>
            <p>
              If you want to start customising the studio look and feel you can follow Sanity's own
              guide on their docs:{' '}
              <a href="https://www.sanity.io/docs/styling" target="blank">
                SanityCMS Styling the studio
              </a>
            </p>

            <p>
              Full SanityCMS documentation can be found here:{' '}
              <a href="https://www.sanity.io/docs" target="blank">
                SanityCMS Documnetation
              </a>
            </p>

            <p>
              Docs for gatsby-source-sanity plugin:{' '}
              <a href="https://www.gatsbyjs.com/plugins/gatsby-source-sanity/" target="blank">
                gatsby-source-sanity
              </a>{' '}
            </p>
            <h4>Firebase</h4>
            <p>
              The fun stuff...We use firebase to authenticate users and store real time user data
              (chat) as well as user uploads and some other cool stuff. So where do we start?
            </p>
            <p>
              A good starting point would be this:{' '}
              <a
                href="https://www.udemy.com/course/gatsby-js-firebase-hybrid-realtime-static-sites/"
                target="blank">
                Gatsby/Firebase Hybrid - Udemy Course
              </a>
              . <br />
              Seen as we use GatsbyJs for the majority of our projects this will help you get up to
              speed with integrating Firebase's features with the Gatsby framework.
            </p>
            <p>
              Once, you've gotten fed up with Udemy questionnaires and popups requesting feedback,
              you can move on to some of the Fireship resources below. These go into a little more
              detail on Firebase security rules and data modelling.
            </p>
            <p>
              <a href="https://fireship.io/courses/firebase-security/" target="blank">
                Firebase Security Course
              </a>
              <br />
              <a href="https://fireship.io/courses/firestore-data-modeling/" target="blank">
                Firestore Data Modelling Course
              </a>
            </p>
            <p>
              We also recommended that you read up on all{' '}
              <i>(but probably not "all")</i> of
              <br />
              <a
                href="https://medium.com/firebase-developers/should-i-query-my-firebase-database-directly-or-use-cloud-functions-fbb3cd14118c"
                target="blank">
                Doug Stevensons Firebase posts
              </a>{' '}
              on his Medium blog
            </p>

            <h3>Useful Learning Resources</h3>
            <h4>EggHead</h4>
            <p>
              EggHead's website has a lot of useful, but super fast{' '}
              <i>(as in stop and pause every 5 seconds to code along)</i> tutorials on everything
              from React, to Gatsby, to Git and many more topics. The login is connected to our
              Github account. Contact Conor for details.
              <br />
              <a href="https://egghead.io/" target="blank">
                EggHead Website
              </a>
            </p>
            <h4>Udemy</h4>
            <p>
              I think we all know this one. Plenty of tutorials on Vocal Training, Sports Coaching
              and General Life Skills such as <a href="https://www.udemy.com/course/happiness-life-coach-certification/" target="blank">Helping People Find Happiness</a>, but they do actually, behind all of the balderdash have a quite a few slightly more lengthy courses
              on everything code related.
              <br />
              The login is dev@agencyx.ie and the password should be availbale through 1Password.
              <br />
              <a href="https://www.udemy.com/courses/development/web-development/" target="blank">
                Udemy Website
              </a>
            </p>
            <h4>Fireship</h4>
            <p>
              Decent courses <i>(apparently)</i>, but limited options.
              <br />
              Again, the login is dev@agencyx.ie and the password should be availbale through 1Password.
              <br />
              <a href="https://fireship.io/courses/" target="blank">
                Fireship Website
              </a>
            </p>
            <h4>LevelUpTuorials</h4>
            <p>
              Now, these guys are good. They also don't code like somebody with a Derry accent spewing out 90 words per minute. You'll be able to code along without having one finger resting on the pause button.
              <br />
              Yet again, the login is dev@agencyx.ie and the password should be availbale through 1Password.
              <br />
              <a href="https://leveluptutorials.com/tutorials" target="blank">
                Level Up Tutorials Website
              </a>
            </p>
            <h4>Anything Else You Want To Learn?</h4>
            <p>Well, if you fancy doing something like a Udacity Nanodegree, the Agency can contribute 50% towards your learning if it will benefit the agency in return. As long as you aren't jumping ship within the following 6 months. Contact "Brian - The Bossman" for more details on that.</p>
          <h3>Password Manager</h3>
          <p>Shhh!...We definitely shouldn't be mentioning passwords on here.</p>
          <p><i>...but seen as we are</i></p>
          <p>We use 1Password to keep all of our passwords secure <i>(and so as not to lose or forget the 50 million different passwords we try to store in our heads).</i></p>
          <p>To set it up in your browser got to: <a href="https://chrome.google.com/webstore/category/extensions" target="blank">The Chrome Web Store</a><br/>
          Search for "1 Password - Password Manager" and click add to chrome.</p>
          <p>Then go here: <a href="https://promozoo-ireland.1password.com/signin" target="blank">Login</a><br/>Finally, contact Conor for details.</p>
          <h3>That's All Folks</h3>
          <p>So, you should have enough to get you started with all of that, but if you feel there's any missing pieces to our little onboarding puzzle feel free to let Conor know and he can escalate your request, bring it up with sales, try to find some time for it, probably get tied up in yet another meeting, but eventually, when time allows and the stars have aligned just right, this onboarding page <i>WILL</i> be updated.</p>
          </Container>
        </InnerWrapper>
      </Wrapper>
    </>
  );
};

const BackButton = styled(Link)`
  align-items: center;
  border-radius: 0.125rem;
  display: flex;
  height: 3rem;
  justify-content: center;
  margin-top: -0.75em;
  margin-bottom: 2rem;
  transition: 150ms ease-in-out;
  width: 3rem;

  &:hover {
    background-color: #000;
  }

  &:not(:hover) {
    background-color: var(--ax-gold);
  }

  &:active {
    background-color: #c1beac;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  h2 {
    font-weight: 800;
    font-size: 2rem;
    margin-bottom: 1em;
  }
  h3 {
    font-size: 2.5rem;
    font-weight: 300;
    color: var(--ax-gold);
    margin-bottom: 0.5em;
    margin-top: 1em;
  }
  h4 {
    font-size: 1.5rem;
    font-weight: 800;
    margin-top: 1em;
    margin-bottom: 0.5em;
  }
  a {
    text-decoration: underline;
    color: var(--ax-gold);
  }
`;

const InnerWrapper = styled.div`
  grid-column: 2 / 3;
  max-width: 62.5rem !important;
  width: 100%;

  @media screen and (min-width: 48rem) {
    margin-top: -4.825rem;
  }

  @media screen and (min-width: 68.75rem) {
    margin: -3.5rem 0 9.1rem;
  }
`;

const Wrapper = styled.section`
  padding-top: 7.1rem;
  position: relative;
  min-height: auto;

  h1 {
    font-size: 3rem;
    font-weight: 900;
    letter-spacing: 0.01em;
    line-height: 1.208em;
    text-transform: capitalize;
    margin-bottom: 0.5em;
    span {
      font-size: 0.5em;
      color: var(--ax-gold);
      font-weight: 300;
    }
  }

  p {
    font-size: 1.25rem;
    letter-spacing: 0.001em;
    line-height: 1.6em;
    margin-bottom: 1em;
  }

  @media screen and (min-width: 48rem) {
    padding-top: 11.9rem;
    min-height: auto;
    h1 {
      font-size: 6rem;
      font-weight: 900;
      line-height: 1.219em;
    }
  }
`;

export default OnboardingDevelopers;
